function sliderMain() {
    var swiper = new Swiper('.main .swiper', {
        slidesPerView: '4',
        loop: false,
        navigation: {
            nextEl: '.main .swiper-button-next',
            prevEl: '.main .swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 0,
            },
            768: {
                slidesPerView: 2,
            },
            1200: {
                slidesPerView: 3,
            },
            1400: {
                slidesPerView: 4,
            },
        }
    })
}

function sliderServices() {
    var swiper = new Swiper('.services .swiper', {
        slidesPerView: '7',
        loop: false,
        navigation: {
            nextEl: '.services .swiper-button-next',
            prevEl: '.services .swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            400: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 7,
            },
        }
    })
}

function sliderBrands() {
    var swiper = new Swiper('.brands .swiper', {
        slidesPerView: 'auto',
        spaceBetween: 34,
        centeredSlides: true,
        loop: true,
        navigation: {
            nextEl: '.brands .swiper-button-next',
            prevEl: '.brands .swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: '1',
                centeredSlides: false,
                spaceBetween: 20,
            },
            400: {
                slidesPerView: '2',
                centeredSlides: false,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: '3',
                centeredSlides: false,
                spaceBetween: 20,
            },
            992: {
                slidesPerView: 'auto',
                spaceBetween: 34,
                centeredSlides: true,
            },
        }
    })
}

function sliderStages() {
    var swiper = new Swiper('.stages .swiper', {
        slidesPerView: '4',
        spaceBetween: 20,
        loop: false,
        navigation: {
            nextEl: '.stages .swiper-button-next',
            prevEl: '.stages .swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
                spaceBetween: 0,
            },
            768: {
                slidesPerView: 2,
            },
            1200: {
                slidesPerView: 3,
            },
            1400: {
                slidesPerView: 4,
            },
        }
    })
}

function sliderWorks() {
    var swiper = new Swiper('.works .swiper', {
        slidesPerView: '1',
        spaceBetween: 20,
        loop: false,
        navigation: {
            nextEl: '.works .swiper-button-next',
            prevEl: '.works .swiper-button-prev',
        },
    })
}

function sliderAdvantages() {
    var swiper = new Swiper('.advantages .swiper', {
        slidesPerView: '3',
        spaceBetween: 20,
        loop: false,
        navigation: {
            nextEl: '.advantages .swiper-button-next',
            prevEl: '.advantages .swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 2,
            },
            1200: {
                slidesPerView: '3',
            },
        }
    })
}

function sliderReviews() {
    var swiper = new Swiper('.reviews .swiper', {
        slidesPerView: '2',
        spaceBetween: 20,
        loop: false,
        navigation: {
            nextEl: '.reviews .swiper-button-next',
            prevEl: '.reviews .swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            992: {
                slidesPerView: 2,
            },

        }
    })
}

function sliderStages2() {
    var swiper = new Swiper('.stages2 .swiper', {
        slidesPerView: '3',
        spaceBetween: 30,
        loop: false,
        navigation: {
            nextEl: '.stages2 .swiper-button-next',
            prevEl: '.stages2 .swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 2,
            },
            1300: {
                slidesPerView: 3,
            },

        }
    })
}

function sliderModels() {
    var swiper = new Swiper('.models .swiper', {
        slidesPerView: '6',
        spaceBetween: 25,
        loop: false,
        navigation: {
            nextEl: '.models .swiper-button-next',
            prevEl: '.models .swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            650: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 6,
            },
        }
    })
}

$(document).ready(function() {

sliderMain();
sliderServices();
sliderBrands();
sliderStages();
sliderWorks();
sliderAdvantages();
sliderReviews();
sliderStages2();
sliderModels();

    // services
    $(".header__item").hover(onIn, onOut);
    $(".header__item .header__item-show").click(function() {
        if ($(this).parents(".header__item").hasClass("header__item--active")) {
            $(this).parents(".header__item").removeClass("header__item--active")
        } else {
            $(this).parents(".header__item").addClass("header__item--active")
        }
    })

    // menu
    $('.header__burger').click(function () {
        $(this).toggleClass('header__burger--active')
        $('.header__center').toggleClass('header__center--active')
        $('body').toggleClass('fixed-body')
    })

    // form
    $(".form-group input").each(function(index, el) {
        InputIsFilled($(el))
    });

    $(".form-group textarea").each(function(index, el) {
        InputIsFilled($(el))
    });

    $(".form-group input").change(function() {
        InputIsFilled($(this))
    })

    $(".form-group textarea").change(function() {
        InputIsFilled($(this))
    })

    function InputIsFilled(el) {
        if ($(el).val()) {
            $(el).addClass("input-active")
        } else {
            $(el).removeClass("input-active")
        }
    }

    // form
    $('.cost__btn').click(function () {
        $('.cost__form2').css({'display': 'block'})
        $('.cost__form').css({'display': 'none'})
    })

        // modal

    $("[data-modal]").click(function () {
        let modalId = $(this).attr("data-modal")

        openModal(modalId)
    })

    function openModal(modalId){
        $(`#${modalId}`).addClass("modal--active")
        $("body").addClass("fixed-body")
    }

    $(".modal").click(function () {
        closeModal()
    })

    $("._close-modal").click(function () {
        closeModal()
    })

    $(".modal__dialog").click(function (e) {
        e.stopPropagation()
    })


    function closeModal() {
        $('.modal').removeClass("modal--active")
        $("body").removeClass("fixed-body")
    }

    // icon
    $(".repair__item").mouseenter(function () {
        let path = $(this).attr("data-tab-path")
        $(this).siblings(".repair__item").removeClass("repair__item--active")
        $(this).addClass("repair__item--active")

        $(this).parents(".repair__inner").find(".repair__list-item").removeClass("repair__list-item--active")
        $(this).parents(".repair__inner").find(`.repair__list-item[data-content-path="${path}"]`).addClass("repair__list-item--active")
    })

    $(".repair__list-item").mouseenter(function () {
        let path = $(this).attr("data-content-path")
        $(this).siblings(".repair__list-item").removeClass("repair__list-item--active")
        $(this).addClass("repair__list-item--active")

        $(".repair__item").removeClass("repair__item--active")
        $(`.repair__item[data-tab-path="${path}"]`).addClass("repair__item--active")
    })

    // brands2
    $('.brands2__btn').click(function () {
        $(this).toggleClass('brands2__btn--active')
        $('.brands2__items').toggleClass('_show')
    })

})

function onIn() {
    if (window.innerWidth > 1300) {
        let el = $(this)
        setTimeout(function() {
            if (el.is(':hover')) {
                console.log(el)
                el.addClass("header__item--active")

            }
        }, 200);
    }
}

function onOut() {
    if (window.innerWidth > 1300) {
        $(this).removeClass("header__item--active")
    }
}